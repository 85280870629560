// Base layout for a columnar station page
// Include this in your station styles - it is not automatically included.

@import "pv2-variables";

$content-border-radius: 1.25rem !default;
$tablet-column-width: 44rem !default;
$desktop-column-width: 38rem !default;

.station {
  // Mobile, allows scrolling past the mobile player
  &.content-started {
    margin-bottom: 9rem;
  }

  .desktop-player-container {
    display: none;
  }

  .mobile-player-container {
    //border-top: 1px solid $gray-400;
    //background-color: rgba(230, 232, 230, 95%);

    display: none;
    padding: .5rem 0;
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    text-align: center;

    .player-centered {
      margin: 0 auto;
    }
  }

  &.content-started .mobile-player-container {
    display: block;
  }

  .station-landing {
    margin: 0 auto 1rem;
  }

  .session-content, .explore-content, .desktop-player {
    backdrop-filter: blur(7.5px);
  }

  .session-column {
    align-self: flex-start;
  }

  .explore-column {
    min-height: 80vh;

    .explore-content {
      min-height: 100%;
      padding-bottom: 1rem;
    }
  }

  .desktop-player {
    background: linear-gradient(149deg, rgba(255, 255, 255, 0.40) 6.57%, rgba(255, 255, 255, 0.10) 92.38%);
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.20);
    padding: .5rem;
  }

  &.known-instance .desktop-player, &.content-started .desktop-player {
    display: none;
  }

  @media screen and (max-width: $tabletUpBreakpoint - 1) {
  }

  @media screen and (min-width: $tabletUpBreakpoint) {

    .station-landing {
      margin-top: 1.5rem;
      max-width: $tablet-column-width;
    }

    .session-column, .explore-column {
      box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.20);
      max-width: $tablet-column-width;
      border-radius: $content-border-radius;
    }

    .session-column .session-content, .explore-column .explore-content {
      border-radius: $content-border-radius;
    }

  }

  @media screen and (min-width: $desktopUpBreakpoint) {

    .station-landing {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      gap: 1.5rem;
      margin-bottom: 1rem;

      .explore-content {
        width: $desktop-column-width;

        /* station specific
        .top-nav {
          background: linear-gradient(138deg, #1F6876 31.65%, #417E8A 73.15%);
        }
        */
      }

    }

    &.has-desktop-player {
      margin-bottom: 0;

      .mobile-player-container, &.content-started .mobile-player-container {
        display: none;
      }

      .desktop-player {
        display: block !important;
        padding: 1rem;
        border-radius: $content-border-radius;

        .desktop-player-container {
          margin-top: .75rem;
          display: block;
        }
      }

    }
  }

  // Tablet landscape handling
  @media screen and (min-width: 1250px) {
    .session-content {

    }
    .desktop-player {
      width: $desktop-column-width;
    }
  }
}
