@import "pv2-variables";

// Header layout, style in app
header.station-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .station-logo-container, .donate-button-container {
    flex-grow: 0;
  }

  .app-name {
    flex-grow: 2;
    text-align: center;

    h1 {
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
      color: var(--brand-inverse-text-color);
    }
  }

  @media screen and (min-width: $tabletUpBreakpoint) {

    .app-name {
      h1 {
        font-size: 1.7rem;
      }
    }
  }
}
