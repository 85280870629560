// Library Component Variables
h1, h2, h3, h4 {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
}

.now-playing-container {
  --now-playing-border-color: #{$knkx-dark-blue};
}

.v2-player {
  --player-text-color: #{$brand-primary-text-color};
  --player-progress-color: #{$brand-primary-text-color};
  --player-progress-border: #{$brand-primary-text-color};
  --player-progress-box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  --player-button-color: #{$knkx-blue};
  --player-button-hover-color: #{$knkx-green};
  --player-play-button-background: #{$knkx-blue};
}

.program-details, .program-list {
  --header-background: #{$knkx-green};
}

.content-list {
  --highlight-color: #{$gray-300};
  --border-color: #{$brand-primary-text-color};
}
.play-button {
  --play-button-background: #{$knkx-blue};
  --play-button-color: #{$brand-inverse-text-color};
  --play-button-hover-color: #{$brand-inverse-text-color};
  --play-button-hover-background: #{$knkx-dark-blue};
}
.page-header {
  --page-header-background: #{$knkx-green};
}

.top-nav {
  --top-nav-background: #{$knkx-blue};
  --top-nav-link-color: #{$brand-inverse-text-color};
  --top-nav-link-hover-background: #{$brand-inverse-text-color};
  --top-nav-link-hover-color: #{$knkx-blue};
}

.tag-nav {
  --border-color: #{$brand-primary-text-color};
  --hover-background: #{$gray-300};
  --selected-background: #{$knkx-green};
  --selected-color: #{$brand-inverse-text-color};
}

.now-playing-page {
  --silence-w1-color: #{$knkx-green};
  --silence-w2-color: #{$knkx-dark-blue};
  --silence-w3-color: #{$knkx-red};
  --silence-w4-color: #{$gray-900};
}

.track-info, .track-banner {
  .track-name {
    color: var(--brand-primary-text-color, $primary-text-color);
  }
}
