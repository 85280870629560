// Removes the browser default link styling from the link.  Be sure to replace it with alternate link indicators.
a.clean-link {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

.column-content-container {
  padding: 0 .5rem;
  margin: .5rem 0;
}

.details-container {
  padding: 0 1rem;
  margin: .5rem 0;
}

.clickable {
  cursor: pointer;
}

.single-line-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: $tabletUpBreakpoint - 1) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (max-width: $desktopUpBreakpoint - 1) {
  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: $tabletUpBreakpoint) {
  .mobile-only {
    display: none;
  }
}

@media screen and (min-width: $desktopUpBreakpoint) {
  .hide-on-desktop {
    display: none;
  }
}
