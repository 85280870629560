@import "./pv2-variables";

.now-playing-container {
  color: var(--now-playing-color, $primary-text-color);
  border: 1px solid var(--now-playing-border-color, $gray-300);
  border-radius: .5rem;
  background-color: var(--now-playing-background, inherit);
  //min-height: 5rem;
  padding: .5rem;
  /*
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
*/
  .message-wrapper, .headline {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: var(--now-playing-color)
  }
  p {
    text-align: center;
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    min-height: 8rem;
  }
}
