@import "pv2-variables";

// Standard styling for content items, to be used between details and lists

.primary-image {
  text-align: center;
  img {
    max-width: 100%;
    margin: 0;
  }
  margin-bottom: 1rem;
}

.content-name {
  color: var(--brand-primary-text-color, $primary-text-color);
  font-weight: 700;
  font-size: 1rem;
}

.content-tag-line {
  font-style: italic;
  font-size: .9rem;
}

.content-host-name {
  font-size: .8125rem;
  margin-top: .2rem;
}

.content-description, .content-extended-info {
  font-size: 0.875rem;
  margin: .5rem 0;
}

.content-extended-info {
  .property-name {
    font-weight: bolder;
    color: var(--brand-primary-text-color, $primary-text-color);
    margin-right: .4rem;
  }
}

.branded-content {
  .content-name {
    font-size: 1.05rem;
  }
  .playback-button {
    float: right;
  }
}

@media screen and (min-width: $tabletUpBreakpoint) {
  .primary-image {
    img {
      max-width: 400px;
    }
  }

  .content-name {
    font-size: 1.1rem;
  }

  .content-tag-line {
    font-size: 1rem;
  }

  .content-host-name, .content-description, .content-extended-info {
    font-size: .95rem;
  }
}
