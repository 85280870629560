@import "pv2-variables";

h2.top-header {
  text-align: center;
  padding-top: 1rem;
  margin: 0 0 .75rem;
  color: var(--brand-primary-text-color, inherit);
  //font-family: Altair;
  font-size: 1.2rem;
  font-weight: 700;

  @media screen and (min-width: $tabletUpBreakpoint) {
    padding: 1.25rem 0 .25rem;
    font-size: 1.5rem;
  }
}

.top-nav {
  background-color: var(--top-nav-background);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);

  a {
    color: var(--top-nav-link-color, $inverse-text-color);
    text-align: center;
    font-size: 1.1rem;
    min-width: 6rem;
    padding: .375rem 1rem;
    border-radius: .375rem;
    border: 1px solid var(--top-nav-link-color, $inverse-text-color);
    text-decoration: none;
  }

  a:hover, a:active, a.active {
    background-color: var(--top-nav-link-hover-background, $inverse-text-color);
    color: var(--top-nav-link-hover-color, $primary-text-color);
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    a {
      min-width: 8rem;
      padding: .5rem 1rem;
    }
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  min-height: var(--page-header-height, 3.5rem);
  //justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  color: var(--page-header-color, $inverse-text-color);
  background-color: var(--page-header-background, $gray-600);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
  }

  // Left hand side back link, only shows on mobile
  a.mobile-back-link {
    color: inherit;
    font-size: 1.3rem;
    margin-right: 1.25rem;
    margin-left: -.5rem;
  }

  // Right hand side navigation link
  .nav-links {
    margin-left: auto;
    a {
      color: inherit;
      font-size: 1.1rem;
      padding: .25rem .5rem;
      border-radius: .25rem;
    }

    a:hover {
      background: var(--page-header-color, $inverse-text-color);
      color: var(--page-header-background, $gray-900);
    }

    margin-bottom: .5rem;
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    min-height: 6rem;
    h3, a.mobile-back-link {
      font-size: 2.5rem;
    }
  }
}


.section-divider {
  //font-family: Altair;
  display: flex;
  flex-wrap: nowrap;
  color: var(--brand-primary-text-color);
  border-bottom: 1px solid var(--brand-primary-text-color);
  padding: .5rem 1rem .25rem;
  margin: 1rem 0 .5rem;

  h3, h4, .h3, .h4 {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
  }
  h4, .h4 {
    font-size: 1.125rem;
  }

  a.nav-link {
    margin-left: auto;
    color: inherit;
    font-size: .9rem;
    padding: .1rem .75rem .15rem;
    border-radius: .25rem;
    //text-decoration: none;
  }

  a:hover {
    background-color: var(--brand-primary-text-color, $gray-700);
    color: var(--brand-inverse-text-color);
  }
}

.column-content-container .section-divider {
  padding-left: .75rem;
}
