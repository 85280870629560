@import "pv2-variables";

.content-list {
  .list-item-wrapper {
    padding: .5rem .75rem;
    border-bottom: 1px solid var(--brand-inverse-border-color, $gray-400);

    &:hover {
      //border: 1px solid var(--highlight-color, #E0E0E0);
      background-color: var(--highlight-color, $gray-300);
    }

  }
}

.content-list-item {
  display: flex;
  align-items: center;

  .button-column {
    padding-right: 1rem;
  }

  .info-column {
    flex-grow: 2;
    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      .content-name {
        text-decoration: underline;
      }
    }
  }

  .count-column {
    min-width: 5rem;
    text-align: right;
    font-size: .8125rem;
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    .count-column {
      display: block;
    }
  }
}
