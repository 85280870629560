// KNKX Brand Colors
$knkx-blue: #1F6876;
$knkx-dark-blue: #17525E;
$knkx-green: #669940;
$knkx-red: #E4643D;
$jazz24-green: #40b97c;
$jazz24-blue: #3255A4;
$brand-white: #fff;
$brand-primary-text-color: $knkx-blue;
$brand-inverse-text-color: $brand-white;

.knkx-green-background {
  background-color: $knkx-green !important;
}

.jazz24-green-background {
  background-color: $jazz24-green !important;
}

:root {
  --knkx-blue: #{$knkx-blue};
  --knkx-green: #{$knkx-green};
  --knkx-dark-blue: #{$knkx-dark-blue};
  --knkx-red: #{$knkx-red};

  --brand-primary-text-color: #{$brand-primary-text-color};
  --brand-inverse-text-color: #{$brand-inverse-text-color};
  --brand-inverse-background: #{$knkx-blue};
  --brand-dark-border-color: #{$knkx-blue};
  --brand-light-border-color: #{$gray-400};

  --button-dark-background: #{$knkx-blue};
  --button-dark-hover-background: #{$knkx-green};
}



