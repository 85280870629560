@import "pv2-station-page";

.station {

  .mobile-player-container {
    border-top: 1px solid $gray-400;
    background-color: rgba(230, 232, 230, 95%);
  }

  &.content-started .mobile-player-container {
    display: block;
  }

  .desktop-player {
    background: linear-gradient(149deg, rgba(255, 255, 255, 0.40) 6.57%, rgba(255, 255, 255, 0.10) 92.38%);
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.20);
    padding: .5rem;
  }

  &.known-instance .desktop-player, &.content-started .desktop-player {
    display: none;
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    .session-column, .explore-column {
      background: linear-gradient(149deg, rgba(255, 255, 255, 0.40) 6.57%, rgba(255, 255, 255, 0.10) 92.38%);
    }

  }


}
